var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('list-settings',{on:{"belovedItems":v => (_vm.belovedItems = v)}}),_vm._v(" "),_c('list-search',{staticClass:"mb-4",attrs:{"searching":_vm.searching},on:{"collapse":v => (_vm.advancedCollapsed = v)}}),_vm._v(" "),_c('div',{staticClass:"item-list"},[(_vm.loading)?_c('div',[_c('base-spinner',{staticClass:"mb-32",attrs:{"large":""}})],1):_c('div',{staticClass:"mb-8 mb-tablet-32"},[((_vm.highlightQuery || _vm.advanced) && _vm.activeSearch)?_c('search',{attrs:{"searchApi":"nfz_search","filters":_vm.healthfundFilters,"mapItems":_vm.mapHealthItems,"advancedCollapse":_vm.advancedCollapsed,"sublist":""},on:{"advanced-open":function($event){_vm.advancedCollapsed = false},"loading":v => {
            this.searching = v;
          }},scopedSlots:_vm._u([{key:"default",fn:function({items, advanced}){return [_c('healthfund-list',{staticClass:"mb-4",attrs:{"items":_vm.belovedItems
              ? items.filter(item => _vm.belovedItems.some(b => b.id === item.id))
              : items,"searchHighlights":{
            query: `v0/healthfund/load_search_highlights/:id?${_vm.highlightQuery}`,
            keywords: _vm.highlightKeywords,
          },"narrow":advanced && !_vm.advancedCollapsed},on:{"toggle-item":function($event){_vm.advancedCollapsed = $event}}})]}}],null,false,2806024410)}):[_c('healthfund-list',{staticClass:"mb-4",attrs:{"items":_vm.belovedItems ? _vm.belovedItems : _vm.items}}),_vm._v(" "),(_vm.items.length < _vm.count)?_c('base-more-button',{attrs:{"loading":_vm.loadingPage},on:{"click":_vm.loadNext}}):_vm._e()]],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }