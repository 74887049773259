export const healthfundFilters = [
  {
    value: '',
    label: 'tytuł',
    name: 'title',
    type: 'text',
    highlightKeyword: 'title_keyword',
  },
  {
    value: '',
    label: 'tekst',
    name: 'text',
    type: 'text',
    keys: ['full_text_2d', 'full_text_w', 'full_text_m', 'full_text'],
    splitRequests: true,
    highlight: true,
  },
  {
    value: '',
    label: 'departament',
    name: 'department',
    type: 'text',
  },
  {
    value: '',
    label: 'zmieniane zarządzenia',
    name: 'amend',
    type: 'text',
    keys: ['amended_short', 'amended_full'],
    splitRequests: true,
    highlightKeyword: 'amended_keyword',
  },
  {
    value: '',
    label: 'uchylane zarządzenia',
    name: 'derogated',
    type: 'text',
    highlightKeyword: 'derogated_keyword',
  },
  {
    value: '',
    label: 'podstawa prawna',
    name: 'legal_basis__title',
    type: 'text',
    highlight: true,
  },
  {
    value: [],
    label: 'status',
    name: 'status',
    type: 'select',
    options: [
      { value: 'A', label: 'prace w toku' },
      { value: 'F', label: 'obowiązuje' },
      { value: 'R', label: 'archiwalny' },
    ],
  },
  {
    value: '',
    label: 'opis i uzasadnienie',
    name: 'descriptions_filter',
    type: 'text',
    highlightKeyword: 'description_keyword',
  },
  {
    value: '',
    label: 'numer',
    name: 'number',
    type: 'text',
  },
];
